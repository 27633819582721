


























































import { Component, PropSync, Vue } from 'vue-property-decorator';
import ProductIsUnavailableToHireViewModel
  from '@/vue-app/view-models/components/my-investment-dashboard-v2/product-is-unavailable-to-hire-view-model';

@Component({
  name: 'ProductIsUnavailableToHireDialog',
  components: {},
})
export default class ProductIsUnavailableToHireDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  @PropSync('productName', { type: String, required: true })
  synced_product_name!: string;

  view_model = Vue.observable(
    new ProductIsUnavailableToHireViewModel(),
  );

  accept() {
    this.closeModal();
    this.view_model.accept();
  }

  closeModal() {
    this.synced_is_open = false;
  }
}
