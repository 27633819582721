import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { Router } from '@/modules/shared/domain/router';

export default class ProductIsUnavailableToHireViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  readonly i18n_namespace = 'components.my-investment-dashboard.product-is-unavailable-to-hire';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  accept = () => {
    this.router.navigate('/update-identity-document-authenticated');
  }
}
